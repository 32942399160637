import React, { Component } from 'react';
import { Col, Row, Form, Button, ButtonToolbar, Container } from 'react-bootstrap';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import OrderData from '../components/DataTable/OrderData';
import OrderDetailModal from '../components/DataTable/Order-Detail-Modal';
import authService from '../components/api-authorization/AuthorizeService'
import { connect } from 'react-redux';
import { createNewOrder } from '../actions/cart-actions';
import { updateSearchList, updateSearchListt, returnUpdateSearch, updateOrderSearchList } from '../actions/search-actions';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Icon from '@material-ui/core/Icon';
class MyOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderdata: [], loading: true, data: '', reportcheck: 'All',
            orderDetail: [], orderDetailDate: []
        };

        this.props.updateOrderSearchList([
            {
                id: '1',
                caption: 'Order Number',
                name: 'orderNumber'
            }]);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeradiobutton = this.handleChangeradiobutton.bind(this);
        this.props.updateSearchList([]);
        this.props.updateSearchListt([]);
        this.props.returnUpdateSearch();
        //this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ data: event.target.value, loading: false });
        this.props.history.replace({ state: event.target.value });
         
        this.setState({
            orderDetail: [], loading: true, reportcheck: this.props.location.statee
        });
    }
    handleChangeradiobutton = e => {
        const { name, value } = e.target;
        this.setState({
            reportcheck: e.target.value
        });
        this.setState({ dataa: e.target.value, loading: false });
        this.props.history.replace({ statee: e.target.value });
        this.setState({
            [name]: value,
        });
    };


    //handleSubmit(event) {
    //    //alert('Your favorite flavor is: ' + this.state.data);
    //    //window.location.reload(true);
    //    this.props.history.push({
    //        pathname: '/MyOrders',
    //        state: this.state.data
    //    })
    //}
     
    checkData = (orderNumber, status) => {
        this.setState({
            orderNumber: orderNumber, status: status.original.orderStatus, warehouse: status.original.warehouse,orderDetail: [], loading: true
        });
        this.populateOrderDetailById(orderNumber);
        
    }

    async populateOrderDetailById(orderNumber) {
            const token = await authService.getAccessToken();
        const response = await fetch('Order/Detail?id=' + orderNumber, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        //for (var i = 0; i < data.manifestList.length; i++) {
        //    var cost = 0.0;
        //    var cost = (data.manifestList[i].cost) * 1.15;
        //    data.manifestList[i].cost = "$" + cost.toFixed(2);
        //}
        this.setState({ orderDetail: data.manifestList, loading: false });
        this.setState({ orderDetailDate: data.orderDetail, loading: false });
    }

    createNewOrder = () => {
        this.props.createNewOrder()
    }
    navigateToInventory = () => {
        this.props.history.push({
            pathname: "/Inventory"
        })
    }
    ArchiveOrders = () => {
        this.props.history.push({
            pathname: "/ArchiveOrders"
        })
    }
    render() {
        return (
            <ContentLayout>
                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "My Orders", path: "/MyOrders" }
                    ]}
                />
                <div className="bg-grey pt-5 pb-2">  <Button className="text-white" onClick={() => this.navigateToInventory()} variant="outline-secondary" style={{ backgroundColor: "#273546" }}><ArrowBackIcon /> <span>Back to Inventory</span></Button>
                    <Button className="btn text-white" onClick={() => this.ArchiveOrders()} style={{ float: "right", backgroundColor: '#fd7e14', marginRight: '20px', borderColor: '#fd7e14' }}> <span>Archived Orders</span></Button>
                    <Container fluid className="pl-5 pr-5">
                        <Row>
                            <Col lg="8">
                                <form>
                                    <Form.Group className="mb-0 mt-3 w-100 bg-white p-3 search-byshor">

                                        <div>

                                            <div class="form-check form-check-inline">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="All"
                                                    value="All"
                                                    onChange={this.handleChangeradiobutton}
                                                    checked={this.state.reportcheck === "All"}
                                                />
                                                <label class="form-check-label" for="All">All</label>
                                            </div>

                                            <div class="form-check form-check-inline">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="Standard"
                                                    value="Standard"
                                                    onChange={this.handleChangeradiobutton}
                                                />
                                                <label class="form-check-label" for="Standard">Standard</label>
                                            </div>

                                            <div class="form-check form-check-inline">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="TruckLoad"
                                                    value="TruckLoad"
                                                    onChange={this.handleChangeradiobutton}
                                                />
                                                <label class="form-check-label" for="TruckLoad">TruckLoad</label>
                                            </div>

                                        
      </div>


                                        <Form.Label className="pt-1 pl-5">
                                            Sort by Status:
                                        </Form.Label>
                                        <Form.Control as="select" value={this.props.location.state} onChange={this.handleChange}>
                                            <option value="All">All</option>
                                            <option value="Open">Open</option>
                                            <option value="PendingApproval">Pending Approval</option>
                                            <option value="Assigned">Assigned</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Shipped">Shipped</option>
                                            <option value="PartiallyShipped">Partially Shipped</option>
                                            <option value="Cancelled">Cancelled</option>
    {/*                                        <option value="TruckLoad">TruckLoad</option>*/}
                                        </Form.Control>


                                        {/*{this.props.location.state == 'TruckLoad' ? '' : <Button style={{ marginLeft: "25px" }} variant="outline-secondary" onClick={() => this.createNewOrder()}>Add New Order</Button>}*/}                         
                                    </Form.Group>
                                </form>
                            </Col>
                        </Row>
                        <div  className="bg-grey pt-2 pb-0">
                            <Row>
                                <Col lg="8">{
                                 //   <Button className="text-white" onClick={() => this.navigateToInventory()} variant="outline-secondary" style={{ backgroundColor: "#273546" }}><ArrowBackIcon /> <span>Back to Inventory</span></Button>
                                } </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                
                <div className="content invoices-contant border-top bg-grey pt-0 pb-5">
                   
                    <Container fluid className="pl-5 pr-5">
                        <Row className="justify-content-center">
                            <Col md="12"> 
                                <OrderData goto={(orderNumber, status) => this.checkData(orderNumber, status)} data={this.props.location.state} orderNumber={this.props.location.orderNumber} dataa={this.props.location.statee} />
                            </Col>
                            {
                            //    <Col lg="4">
                            //        {this.state.orderDetail != null ? this.state.orderDetail.map(manifestList => {
                            //            return (
                            //                <OrderDetailModal orderNumber={this.state.orderNumber} sbNumber={manifestList.sbNumber} blNumber={manifestList.blNumber} warehouse={this.state.warehouse}
                            //                    hdDotCom={manifestList.hdDotCom} itemQuantity={manifestList.itemQuantity} wholesale={manifestList.cost} ContQuantity={manifestList.containerQuantity}
                            //                    status={this.state.status} DateTime={this.state.orderDetailDate.orderDateTime} salvageCategory={manifestList.salvageCategory} warehouseId={manifestList.warehouse} manifestId={manifestList.id} />
                            //            )
                            //        })
                            //            :
                            //            null}

                            //    </Col>
                            }
                        </Row>
                    </Container>

                </div>
            </ContentLayout>
        );
    }

}


const mapStateToProps = state => ({
    userOrders: state.orders
});

const mapActionsToProps = {
    createNewOrder: createNewOrder,
    updateSearchList: updateSearchList,
    updateSearchListt: updateSearchListt,
    returnUpdateSearch: returnUpdateSearch,
    updateOrderSearchList: updateOrderSearchList
}

export default connect(mapStateToProps, mapActionsToProps)(MyOrders);