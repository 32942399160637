export const dateMDY = (date) => {
    var d = date.split('-');

    if (d.length < 2) {
        return "";
    }

    var year = d[0];
    var month = d[1]
    var day = d[2]

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('-');
}

export const orderStatus = (status) => {
    if (status == 1) {
        return "Open";
    }
    else if (status == 2) {
        return "Assigned";
    }
    else if (status == 3) {
        return "Approved";
    }
    else if (status == 4) {
        return "Shipped";
    }
    else if (status == 5) {
        return "Partially Shipped";
    }
    else if (status == 6) {
        return "Cancelled";
    }
    else if (status == 7) {
        return "Pending Approval";
    }
    //return [month, day, year].join('-');
}
export const warehouse = (id) => {
    if (id == 0) {
        return "NotSpecified";
    }
    else if (id == 8615) {
        return "Indianapolis";
    }
    else if (id == 8616) {
        return "McDonough";
    }
    else if (id == 8617) {
        return "Phoenix";
    }
    else if (id == 8618) {
        return "Pittston";
    }
    else if (id == 9999) {
        return "Other";
    }
    //return [month, day, year].join('-');
}

export const customerPayment = (id) => {
    if (id == 1) {
        return "Prepaid";
    }
    else if (id == 2) {
        return "Consignment";
    }
    else if (id == 3) {
        return "Net Terms";
    }
}
export const warehouses = [
    { label: "NotSpecified", value: "0" },
    { label: "Indianapolis", value: "8615" },
    { label: "McDonough", value: "8616" },
    { label: "Phoenix", value: "8617" },
];

export const Categories = [
    { label: "Not Specified", value: "NotSpecified" },
    { label: "001", value: "ZeroZeroOne" },
    { label: "APPLIANCES", value: "Appliances" },
    { label: "BLINDS", value: "Blinds" },
    { label: "DOORS AND WINDOWS", value: "DoorsOrWindow" },
    { label: "FLOORING AND RUGS", value: "FlooringAndRugs" },
    { label: "HARDWARE AND TOOLS", value: "HardwareAndTools" },
    { label: "HOLIDAY", value: "Holiday" },
    { label: "KITCHEN AND BATH", value: "KitchenAndBath" },
    { label: "LIGHTING", value: "Lighting" },
    { label: "OUTDOOR SEASONAL", value: "OutdoorSeasonal" },
    { label: "PLUMBING", value: "Plumbing" },
    { label: "HOME DECOR", value: "HSHomeDecor" },
    { label: "MIXED LOAD", value: "MixedLoad" },
];

//export const numberformat = (no) => {

//    let number = no.toLocaleString(navigator.language, { minimumFractionDigits: 0 })

//    return number;

//}
//export const numberformat = inputNumber => {
//    let formetedNumber = (Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
//    let splitArray = formetedNumber.split('.');
//    if (splitArray.length > 1) {
//        formetedNumber = splitArray[0];
//    }
//    return (formetedNumber);
//};
export const numberformat = (x) => {

    return "$" +x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const Rateformat = (x) => {
    var p = x.toString();
    return p +"%"
}
export const calculatedynamic = (x,y) => {

    var wholesaleAmtTotall = y;
    var buynoval = x;
      //  .replace(/%/g, "");
    var percentcal = wholesaleAmtTotall * buynoval / 100;
    var valuecheck = percentcal.toFixed(2);
    return "$" + valuecheck.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
